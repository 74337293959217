export const state = () => ({
  selectedSpecialties: []
});

export const getters = {};

export const actions = {
  toggleSpecialityOfSelecteds({ commit, state }, speciality) {
    const specialityIndex = state.selectedSpecialties.indexOf(speciality);

    if (specialityIndex !== -1) {
      return commit('removeSpecialityFromSelecteds', specialityIndex);
    }

    return commit('pushSpecialityToSelecteds', speciality);
  }
};

export const mutations = {
  pushSpecialityToSelecteds(state, speciality) {
    state.selectedSpecialties.push(speciality);
  },
  removeSpecialityFromSelecteds(state, index) {
    state.selectedSpecialties.splice(index, 1);
  }
};

export default {
  name: 'activityPanel',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
