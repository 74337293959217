import * as loggedAsTypes from '@/constants/loggedAsTypes';
import { verifyRouteRolesUser } from '@/router/middlewares';

const PrepareQuote = () => import('./SpecialistStepsComplete/PrepareQuote.vue');
const CheckProposalResponse = () =>
  import('./SpecialistStepsComplete/CheckProposalResponse.vue');
const ProposalExecution = () =>
  import('./SpecialistStepsComplete/ProposalExecution.vue');
const InputProposals = () =>
  import('./SpecialistStepsComplete/InputProposals.vue');
const SupplierApproval = () =>
  import('./SpecialistStepsComplete/SupplierApproval.vue');
const AwaitingConsultant = () =>
  import('./SpecialistStepsComplete/AwaitingConsultant.vue');

export default [
  {
    path: '/activity-panel/proposal-quotation/:diagnosisId(\\d+)',
    name: 'ProposalAndQuotation',
    component: PrepareQuote,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST])
  },
  {
    path: '/activity-panel/check-proposal-response/:diagnosisId(\\d+)',
    name: 'CheckProposalResponse',
    component: CheckProposalResponse,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST])
  },
  {
    path: '/activity-panel/proposal-execution/:diagnosisId(\\d+)',
    name: 'ProposalExecution',
    component: ProposalExecution,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST])
  },
  {
    path: '/activity-panel/input-proposals/:diagnosisId(\\d+)',
    name: 'InputProposals',
    component: InputProposals,
    beforeEnter: (to, from, next) => {
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST]);
    }
  },
  {
    path: '/activity-panel/supplier-approval/:diagnosisId(\\d+)',
    name: 'SupplierApproval',
    component: SupplierApproval,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST])
  },
  {
    path: '/activity-panel/awaiting-consultant/:diagnosisId(\\d+)',
    name: 'AwaitingConsultant',
    component: AwaitingConsultant,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST])
  }
];
