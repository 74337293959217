import Vue from 'vue';
import Vuex from 'vuex';
import auth from '../features/auth/auth.store';
import diagnosis from '../features/diagnosis/diagnosis.store';
import activityPanel from '../features/activityPanel/activityPanel.store';
import modal from './modal.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    diagnosis,
    activityPanel,
    modal
  }
});
