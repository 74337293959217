import store from '@/store';

const publicPages = [
  'login',
  // 'loginSpecialist',
  'forgotPassword',
  'resetPassword'
];

const isAuthenticated = () => {
  return store.getters['auth/isAuthenticated'];
};

export const authGuard = (to, from, next) => {
  const requiresAuth = !publicPages.includes(to.name);

  if (requiresAuth && !isAuthenticated()) {
    return next({
      name: 'login'
    });
  }

  next();
};

export const alreadyLoggedGuard = (to, from, next) => {
  if (isAuthenticated()) {
    return next({
      name: 'home'
    });
  }

  next();
};
