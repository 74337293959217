import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', (value, format = 'D MMM,YY') => {
  if (!value) return '';

  return moment.utc(value).format(format);
});

Vue.filter('formatDateTime', (value) => {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY HH:mm') + 'h';
});

Vue.filter('formatTime', (value) => {
  if (!value) return '';
  return moment(value).format('HH:mm');
});

Vue.filter('uppercase', (value) => {
  if (!value) return '';
  return String(value).toUpperCase();
});

Vue.filter('cnpj', (value) => {
  const cnpj = String(value).replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  );
  return cnpj;
});

Vue.filter('cpf', (value) => {
  if (!value) return '';

  const cnpj = String(value).replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})/,
    '$1.$2.$3-$4'
  );
  return cnpj;
});

Vue.filter('genderName', (value) => {
  if (value === 'M') return 'Masculino';
  if (value === 'F') return 'Feminino';
  if (value === 'O') return 'Outro';

  return '';
});

Vue.filter('maritalStatusName', (value) => {
  if (value === 'C') return 'Casado';
  if (value === 'S') return 'Solteiro';
  if (value === 'D') return 'Divorciado';
  if (value === 'V') return 'Viúvo';
  if (value === 'O') return 'Outros';
  if (value === 'SJ') return 'Separado Judicialmente';
  if (value === 'UE') return 'União Estável';

  return '';
});

Vue.filter('statusSmoke', (value) => {
  if (value === 1) return 'Fumante';
  if (value === 2) return 'Não Fumante';
  if (value === 3) return 'Ex Fumante';

  return '';
});

Vue.filter('periodicidadePagamento', (value) => {
  if (value === 1) return 'Mensal';
  if (value === 2) return 'Anual';

  return '';
});

Vue.filter('periodoCobertura', (value) => {
  if (!value) return '';
  if (Number(value) === 999) return 'Vitalício';

  return `${value} anos`;
});

function valueToDecimal(value, decimal = 2) {
  if (!value) return '0,00';

  let val = (value / 1).toFixed(decimal).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

Vue.filter('decimal', valueToDecimal);

Vue.filter('decimalWithoutCents', (value) => {
  return valueToDecimal(value, 0);
});
