import * as loggedAsTypes from '@/constants/loggedAsTypes';
import { verifyRouteRolesUser } from '@/router/middlewares';
const SalesPanel = () => import('./SalesPanel.vue');

export default [
  {
    path: '/sales-panel',
    name: 'SalesPanel',
    component: SalesPanel,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  }
];
