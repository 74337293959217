import { checkLoggedAs } from '@/features/auth/auth.helpers';
import { SPECIALIST } from '@/constants/loggedAsTypes';

export const verifyRouteRolesUser = (to, from, next, roles) => {
  if (checkLoggedAs(roles)) {
    return next();
  }

  if (checkLoggedAs([SPECIALIST])) {
    return next({ name: 'ActivityPanel' });
  }

  return next({ name: 'ToDoList' });
};
