import glossary from './pt_br/glossary';
import auth from './pt_br/auth';
import navbar from './pt_br/navbar';

const pt_br_translation = {
  ...glossary,
  ...auth,
  ...navbar
};

export var pt_br = pt_br_translation;
