import axios from 'axios';
import Config from '@/config/config';
import router from '@/router';
import store from '@/store';
import { getAuth } from '../features/auth/auth.helpers';

const CONFIG = new Config();

export const api = axios.create({
  baseURL: CONFIG.API_URL
});

api.interceptors.request.use((config) => {
  const auth = getAuth();

  if (auth && auth.token) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      var count = 5;

      let countdown = setInterval(() => {
        count -= 1;
        document.querySelector(
          '#container-fluid'
        ).innerHTML = `<h5>Sessão expirada! redirecionando em ${count} segundos...</h5>`;

        if (count <= 0) {
          clearInterval(countdown);
          store.dispatch('auth/logOut');
          router.push({ path: '/auth/logout' });

          setTimeout(() => {
            document.location.reload(true);
          }, 1000);
        }
      }, 1000);
    }

    return Promise.reject(error);
  }
);

export default api;

export function interceptors(cb) {
  const onSuccess = (res) => res;

  const onError = (err) => {
    cb(err);
    return Promise.reject(err);
  };

  api.interceptors.response.use(onSuccess, onError);
}
