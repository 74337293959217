import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import VueI18n from 'vue-i18n';

import App from './App.vue';
import router from './router';
import store from './store';
import { messages } from './lang/lang';
import Config from './config/config';

import '@/filters/index';
import '@/mixins/index';
import '@/design/index.scss';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueI18n);

Vue.prototype.CONFIG = new Config();

store.state.currentLanguage = localStorage.currentLanguage
  ? localStorage.currentLanguage
  : 'pt_br';

const i18n = new VueI18n({
  locale: store.state.currentLanguage,
  fallbackLocale: 'pt_br',
  messages
});
store.state.languages = Object.keys(messages);

new Vue({
  router,
  store,
  i18n,

  render: (h) => h(App)
}).$mount('#app');
