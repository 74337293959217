export const cloneObject = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export const formatData = (...rest) => {
  let formData = new FormData();
  formData.append(...rest);

  return formData;
};
