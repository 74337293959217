module.exports = {
  MOTOR_INTERNO: 'motor-interno',
  DIAGNOSTICO: 'diagnostico',
  DIAGNOSTICO_DOCUMENTO: 'diagnostico-documento',
  DOWNLOAD: 'download',
  CONTINUAR_DIAGNOSTICO: 'continuar-diagnostico',
  DIAGNOSTICO_APOLICE: 'diagnostico-apolice',
  DEPENDENTES: 'dependentes',
  ESTAGIO_ESPECIALISTA: 'estagio-especialista',
  ESTAGIO: 'estagio',
  EMPRESA: 'empresa',
  FUNCIONARIO: 'funcionario',
  CLIENTE: 'cliente',
  CORRETORES: 'corretores',
  COMENTARIO: 'comentario',
  COMENTARIOS: 'comentarios',
  DIAGNOSTICO_COMENTARIO: 'diagnostico-comentario',
  DIAGNOSTICO_PRODUTO: 'diagnostico-produto',
  DIAGNOSTICO_PRODUTO_ITEM: 'diagnostico-produto-item',
  DIAGNOSTICO_PRODUTO_ITEM_OPCAO: 'diagnostico-produto-item-opcao',
  DIAGNOSTICO_PRODUTO_ITEM_OPCOES: 'diagnostico-produto-item-opcoes',
  PRODUTO_ITEM_TABELA_ITEM: 'produto-item-tabela-item',
  POR_DIAGNOSTICO: 'por-diagnostico',
  POR_DIAGNOSTICO_ESPECIALISTA: 'por-diagnostico-especialista',
  PAINEL_ATIVIDADES: 'painel-atividades',
  PAINEL_VENDAS: 'painel-vendas',
  LISTA_TAREFAS: 'lista-tarefas',
  SEGURO_ITEM: 'seguro-item',
  SEGURADORAS: 'seguradoras',
  GRUPO_SEGURO_ITEM: 'grupo-seguro-item',
  BUSCA: 'busca',
  OPCOES: 'opcoes'
};
