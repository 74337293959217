let base22MessageStyle =
  'font-size:21px; font-weight:200; letter-spacing:0.2em; line-height:1.4em; font-family:helvetica,arial; color:#009B3A;';

console.log('%cMotor de Vendas - SEGASP', base22MessageStyle);

class Config {
  constructor() {
    this.API_URL = 'https://api-crm.segasp-univalores.com.br/segasp/api/api/';
    // this.API_URL = 'http://localhost:8000/segasp/api/api/';
  }
}

export default Config;
