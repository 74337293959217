import * as loggedAsTypes from '@/constants/loggedAsTypes';
import { verifyRouteRolesUser } from '@/router/middlewares';
const ToDoList = () => import('./toDoList.vue');

export default [
  {
    path: '/to-do-list',
    name: 'ToDoList',
    component: ToDoList,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  }
];
