import browser from '@/utils/browser';
import * as loggedAsTypes from '@/constants/loggedAsTypes';
import authStore from './auth.store';

const AUTH_KEY = 'libra-auth';

export const setAuth = (data) => {
  browser.localStorage().setItem(AUTH_KEY, JSON.stringify(data));
};

export const getAuth = () => {
  const data = JSON.parse(browser.localStorage().getItem(AUTH_KEY));
  if (data && !data.loggedAs) {
    data.loggedAs = loggedAsTypes.BROKER;
  }

  return data;
};

export const checkUserRole = (roles) => {
  const user = getAuth().user;
  if (user) {
    return roles.includes(user.pessoa.funcionario.funcionario_cargo_id);
  } else {
    return null;
  }
};

export const checkLoggedAs = (roles) => {
  const loggedAs = authStore.state.loggedAs;

  if (loggedAs) {
    return roles.includes(loggedAs);
  } else {
    return null;
  }
};
