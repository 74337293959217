import * as loggedAsTypes from '@/constants/loggedAsTypes';
import { verifyRouteRolesUser } from '@/router/middlewares';
const DiagnosisComplete = () => import('./DiagnosisComplete.vue');
const GenerateDiagnosisComplete = () =>
  import('./GenerateDiagnosisComplete.vue');
const ConsultantDirection = () => import('./ConsultantDirection.vue');
const ExamResult = () => import('./ExamResult');
const RevenueQuotation = () => import('./RevenueQuotation.vue');
const ProductRealization = () => import('./ProductRealization.vue');
const AwaitingSpecialist = () => import('./AwaitingSpecialist.vue');

export default [
  {
    path: '/diagnosis/complete',
    name: 'DiagnosisComplete',
    component: DiagnosisComplete,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  },
  {
    path: '/diagnosis/complete/:customerId(\\d+)',
    name: 'GenerateDiagnosisComplete',
    component: GenerateDiagnosisComplete,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  },
  {
    path: '/diagnosis/complete/:customerId(\\d+)/:continueDiagnosisId(\\d+)',
    name: 'GenerateDiagnosisCompleteContinue',
    component: GenerateDiagnosisComplete,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  },
  {
    path: '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)',
    name: 'ConsultantDirection',
    component: ConsultantDirection,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  },
  {
    path:
      '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/exam-result',
    name: 'ExamResult',
    component: ExamResult,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  },
  {
    path:
      '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/quotation-result',
    name: 'RevenueQuotation',
    component: RevenueQuotation,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  },
  {
    path:
      '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/product-realization',
    name: 'ProductRealization',
    component: ProductRealization,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  },
  {
    path:
      '/diagnosis/complete/consultant-direction/:diagnosisId(\\d+)/awaiting-specialist',
    name: 'AwaitingSpecialist',
    component: AwaitingSpecialist,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
  }
];
