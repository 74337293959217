/** Global Mixins */

import Vue from 'vue';
import {
  DONO,
  GERENTE,
  CORRETOR,
  ESPECIALISTA,
  LIDER,
  PARCEIRO,
  MASTER
} from '@/constants/userRoles';

Vue.mixin({
  data() {
    return {
      numberInputTranslations: {
        countrySelectorLabel: 'Código do país',
        countrySelectorError: 'Escolha um país',
        phoneNumberLabel: 'Número de telefone',
        example: 'Exemplo: '
      },
      yesOrNotOptions: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' }
      ],
      moneyMask: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    };
  },
  computed: {
    actualYear() {
      return new Date().getFullYear();
    },
    userRoleOwner() {
      return DONO;
    },
    userRoleManager() {
      return GERENTE;
    },
    userRoleBroker() {
      return CORRETOR;
    },
    userRoleSpecialist() {
      return ESPECIALISTA;
    },
    userRoleLeader() {
      return LIDER;
    },
    userRolePartner() {
      return PARCEIRO;
    },
    userRoleMaster() {
      return MASTER;
    }
  }
});
