const ActivityPanel = () => import('./ActivityPanel.vue');

export default [
  {
    path: '/activity-panel',
    name: 'ActivityPanel',
    component: ActivityPanel,
    beforeEnter: (to, from, next) => {
      next({ name: 'CustomersSpecialist' });
    }
  }
];
