import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/features/auth/auth.routes';
import customers from '@/features/customers/customers.routes';
import diagnosis from '@/features/diagnosis/diagnosis.routes';
import salesPanel from '@/features/salesPanel/salesPanel.routes';
import activityPanel from '@/features/activityPanel/activityPanel.routes';
import toDoList from '@/features/toDoList/toDoList.routes';

import specialistSteps from '@/features/SpecialistSteps/specialist.routes.js';

import { authGuard } from '@/features/auth/auth.middleware';
import { verifyRouteRolesUser } from './middlewares';

Vue.use(VueRouter);

const routes = [
  ...auth,
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '*',
    redirect: '404'
  },
  {
    path: '/',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '',
        name: 'home',
        beforeEnter: (to, from, next) =>
          verifyRouteRolesUser(to, from, next, [])
      },
      ...customers,
      ...diagnosis,
      ...salesPanel,
      ...activityPanel,
      ...toDoList,
      ...specialistSteps
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(authGuard);

export default router;
